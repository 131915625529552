import { Component, Inject, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import vuescroll from "vuescroll";
import DfStore from "@/vue/domain/store/df-store";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProperty from "@/vue/domain/content/df-property";
import Swal from "sweetalert2";

@Component({
  components: {
    DfHeaderMobile: () => Utils.externalComponent2("df-header-mobile"),
    DfFormManager: () => Utils.externalComponent2("df-form-manager"),
    DfStoreCard: () => Utils.externalComponent2("df-store-card"),
    vuescroll,
  },
})
export default class DfPageUserRegistrationComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private registrationData: any = {};

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  beforeMount() {
    this.getRegistrationData();
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private getRegistrationData() {
    this.service
      .getRegistrationData()
      .then((registrationData: any) => {
        this.registrationData = registrationData;
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private saveRegistrationData(callbackFunctions: any) {
    const params: any = {
      confirmPassword: this.registrationData["confirmPassword"],
      email: this.registrationData["email"],
      password: this.registrationData["password"],
      privacyConsent: this.registrationData["privacyConsent"],
      clubCardConsent: this.registrationData["clubCardConsent"],
      storeIdentifier: this.currentStore.identifier,
      properties: (this.registrationData.properties || []).map((property: DfProperty) => {
        return {
          type: property.type,
          code: property.code,
          values: property.values.map((value: any) => {
            if (property.type === "LIST") {
              const listValue = value.list ? value.list.key : null;
              return listValue ? { list: listValue } : { list: null };
            } else if (property.code === "TAX_CODE" && value.string) {
              value.string = value.string.toUpperCase();
              return value;
            } else {
              return value;
            }
          }),
        };
      }),
    };
    this.service
      .setRegistrationData(params)
      .then((response: any) => {
        if (callbackFunctions.then) {
          callbackFunctions.then(response);
        }
        Utils.setAnaliticsTraker({
          event: "user_registration",
          origin: "smt-digitalflyer-ui",
          email: this.registrationData["email"],
        });
        Swal.fire({
          title: this.$t("df-page-user-registration.modal.save.title").toString(),
          text: this.$t("df-page-user-registration.modal.save.body").toString(),
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("df-page-user-registration.modal.save.ok").toString(),
        }).then((result) => {
          if (result.value) {
            this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
          }
        });
      })
      .catch((error: any) => {
        if (callbackFunctions.catch) {
          callbackFunctions.catch(error);
        }
      });
  }

}
